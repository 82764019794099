import { PageProps } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import BannerRedesign from "../components/BannerRedesign";
import SectionEzDivisorConsultation from "../components/ReUsables/SectionEzDivisorConsultation";
import {
  GlobalOptions,
  MenuItems,
  ServicesWeOfferType,
  TestimonialsType,
} from "../shared/interfaces/graphql.interface";
import { EzDivisor, RespContainer } from "../styles/common";
import styled from "styled-components";
import { Colors, Devices } from "../styles/variables";
import { FontelloIcon } from "../styles/CommonComponents";
import WorkFlowItem from "../components/ReUsables/WorkFlowItem";
import ServicesWeOffer from "../components/ReUsables/ServicesWeOffer";
import Testimonials from "../components/ReUsables/Testimonials";

// export default ({ pageContext, ...props }) => {

interface PageDataProps {
  pageContext: { data: PageContext };
}

interface PageContext {
  testimonials: TestimonialsType;
  globalOptions: GlobalOptions;
  footerMenuItems: MenuItems;
  headerMenuItems: MenuItems;
  title: string;
  content: string;
  acf: {
    banner_title: string;
    banner_subtitle: string;
    banner_image: {
      localFile: any;
      alt_text: string;
    };
    what_we_do: [
      {
        item: string;
      }
    ];
    what_you_get: [
      {
        item: string;
      }
    ];
    our_work_flow: [
      {
        title: string;
        text: string;
      }
    ];
  };
  service_image: {
    source_url: string;
    alt_text: string;
  };
  services_we_offer: ServicesWeOfferType;
}

const page: React.FC<PageDataProps> = ({ pageContext, ...props }) => {
  let pageContextData: PageContext = pageContext.data;
  const { acf } = pageContextData;
  return (
    <>
      <BannerRedesign
        title={acf.banner_title}
        title_2={pageContextData.acf.banner_title_2}
        subtitle={acf.banner_subtitle}
        banner={acf.banner_image}
        hideButton
      />
      <ServiceDescription>
        <RespContainer>
          <WWDWYGDesc
            dangerouslySetInnerHTML={{ __html: pageContextData.content }}
          />
        </RespContainer>
      </ServiceDescription>
      <RespContainer>
        <WhatWeDoWhatYouGet>
          <WWDWYGCol>
            <WWDWYGTitle>What We Do</WWDWYGTitle>
            <EzDivisor alwaysBlue />

            <WWDWYGList>
              {acf.what_we_do.map((wwd, idx) => (
                <WWDWYGItem key={`wwd${idx}`}>
                  <FontelloIcon icon="ok-1" />
                  {wwd.item}
                </WWDWYGItem>
              ))}
            </WWDWYGList>
          </WWDWYGCol>
          <WWDWYGCol>
            <WWDWYGTitle>What You Get</WWDWYGTitle>
            <EzDivisor alwaysBlue />

            <WWDWYGList>
              {acf.what_you_get.map((wwd, idx) => (
                <WWDWYGItem key={`wyg${idx}`}>
                  <FontelloIcon icon="ok-1" />
                  {wwd.item}
                </WWDWYGItem>
              ))}
            </WWDWYGList>
          </WWDWYGCol>
        </WhatWeDoWhatYouGet>
      </RespContainer>

      <SectionEzDivisorConsultation
        section={pageContextData.globalOptions.ez_divisor_consultation}
        phone={pageContextData.globalOptions.company_information.phone}
      />

      <GrayWrapper>
        <RespContainer>
          {/* <WorkFlow>
            <WFTitle>Our Work Flow</WFTitle>
            <WFDivisor alwaysBlue />

            <WorkFlowList>
              {acf.our_work_flow.map(({ title, text }, idx) => (
                <WorkFlowItem
                  key={`owf${idx}`}
                  title={title}
                  text={text}
                  isLast={acf.our_work_flow.length === idx + 1}
                  index={idx}
                />
              ))}
            </WorkFlowList>
          </WorkFlow> */}

          <GrayWrapper>
            <Testimonials testimonials={pageContextData.testimonials} />
          </GrayWrapper>

          <ServicesWeOffer
            services_we_offer={pageContextData.services_we_offer.filter(
              (service) => service.item.title !== pageContextData.title
            )}
          />
        </RespContainer>
      </GrayWrapper>
    </>
  );
};

export default page;

const WhatWeDoWhatYouGet = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 72px;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    flex-direction: row;

    width: 100%;

    > div:first-child {
      padding-right: 55px;
      width: 51%;
    }

    > div:last-child {
      width: 49%;
    }
  }

  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    > div:first-child {
      padding-right: 134px;
    }
  }
`;

const WWDWYGCol = styled("div")`
  /* @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    padding-right: 162px;
  } */
`;

const WWDWYGTitle = styled("h2")`
  color: ${Colors.dodgeBlue2};
  margin-bottom: 13px;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    font-size: 2rem;
    font-weight: 600;

    margin-bottom: 19px;
  }

  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
  }

  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
  }
`;

const WWDWYGList = styled("ul")`
  list-style-type: none;
  margin-bottom: 61px;
  margin-left: 0;
  margin-top: 24px;
  padding: 0;
  font-size: 18px;
  font-weight: 600;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    font-size: 24px;
  }
`;

const WWDWYGItem = styled("li")`
  margin-bottom: 13px;
  color: ${Colors.dodgeBlue2};
  position: relative;
  font-size: 1.125rem;
  font-weight: 300;
  display: flex;
  align-items: flex-start;

  > span {
    font-size: 30px !important;
    color: ${Colors.sharpBlue};
    ::before {
      margin-left: 0;
      margin-right: 0.5rem;
    }
  }

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    margin-bottom: 1rem;

    > span {
      font-size: 36px !important;
    }
  }
`;

const ServiceDescription = styled("div")`
  background: ${Colors.gray7};
  padding: 3rem 0;
  margin-top: 3rem;
  p {
    font-size: 1rem;
    font-weight: 300;
    margin: 0;
  }
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    padding: 3.5rem 0;
  }
`;

const WWDWYGDesc = styled("div")`
  display: block;
  line-height: 142%;
  font-size: 18px;
`;

const GrayWrapper = styled("div")`
  background: ${Colors.gray7};
  padding: 34px 0;
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
  }
`;

const WorkFlow = styled("div")`
  width: 100%;
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    margin-bottom: 40px;
  }
`;

const WFTitle = styled("h2")`
  font-size: 32px;
  margin-bottom: 25px;
  color: ${Colors.ezBlack};

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    text-align: center;
    margin-top: 80px;
    font-size: 60px;
    font-weight: 600;
    margin-bottom: 33px;
  }
`;

const WFDivisor = styled(EzDivisor)`
  margin-bottom: 30px;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    margin-bottom: 96px;

    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const WorkFlowList = styled("div")`
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    margin: auto;
    max-width: 1150px;
  }
`;
